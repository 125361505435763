import React, { FC } from 'react'
import Image from 'next/image'
import styles from './VideoBlock.module.scss'
import cn from "classnames";
import useWindowDimensions from "../../../customHooks/useWindowDimension";

type VideoBlockProps = {
  containerScaling?: string
}

const VideoBlock:FC<VideoBlockProps> = ({containerScaling}) => {
  const { width } = useWindowDimensions()
  return (
    <section className={cn(styles.sectionMain, containerScaling)} id="temporary-About">
      <div className={styles.sectionMainContainer}>
        <div className={styles.sectionMainHeader}>
          <div className={styles.sectionMainText}>
            Hint: We&apos;re from Norway
            <div className={styles.sectionMainFlag}>
              <Image
                src="/images/aboutUs/flag-norway.svg"
                alt="flag-norway"
                width={35}
                height={26}
                objectFit="contain"
              />
            </div>
          </div>
          <div className={styles.sectionMainHeadline}>Who we are</div>
        </div>
        <div className={styles.sectionMainBody}>
          <div className={styles.video}>
            <div className={styles.videoInner}>
              <div className={styles.videoFrame}>
                <Image
                  src="/images/aboutUs/video.png"
                  alt="video frame"
                  width={1400}
                  height={750}
                  objectFit="cover"
                />
              </div>
            </div>
            <div className={styles.videoDescription}>
              <div className={styles.videoText}>
                <p>
                  Vitaliv — Norwegian brand experienced in creating premium-quality
                  nutritional supplements based on respect for health and nature
                </p>

                <p>
                  We created Vitaliv Club to help you reaching
                  your {width > 1024 ? null : <br/>}<span>health goals</span> the smart way
                </p>

                <div className={styles.wrapperButton}>
                  {width < 1080
                      ? null
                      : <a href='/aboutus' className={styles.button}>Learn more about Vitaliv</a>
                  }
                </div>

              </div>
              <div className={styles.videoSide}>
                <div className={styles.videoList}>
                  <div className={styles.videoItem}>
                    <div className={styles.videoNumber}>8</div>
                    years of experience <br />
                    in Europe and UK
                  </div>
                  <div className={styles.videoItem}>
                    <div className={styles.videoNumber}>10%</div>
                    from population of Norway and Scandinavian countries choose
                    our products
                  </div>
                </div>
              </div>
              <div className={styles.wrapperButton}>
                {width < 1080
                    ? <a href='/aboutus' className={styles.button}>More about Vitaliv</a>
                    : null
                }
              </div>

            </div>
          </div>
        </div>
        {/*<div className={styles.sectionMainFooter}>*/}
        {/*    <a className={styles.sectionMainMore} href="#">Learn more about Vitaliv</a>*/}
        {/*</div>*/}
      </div>
    </section>
  )
}

export default VideoBlock
